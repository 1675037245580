export const getData = async ({ url }) => {
  try {
    const res = await fetch(url);

    return await res.json();
  } catch (err) {
    return { data: null, errors: err.message };
  }
};

export const getDataWithAuth = async ({ token, url }) => {
  try {
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return await res.json();
  } catch (err) {
    return { data: null, errors: err.message };
  }
};

export const postData = async ({ url, body }) => {
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    });
    return await res.json();
  } catch (err) {
    return { data: null, errors: err.message };
  }
};

export const postDataWithAuth = async ({ body, token, url }) => {
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });

    return await res.json();
  } catch (err) {
    return { data: null, errors: err.message };
  }
};
