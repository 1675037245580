import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context.js";

const ClearCache = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  useEffect(() => {
    user.fn({});
    localStorage.removeItem("oldUser");
    navigate("/");
  }, []); // eslint-disable-line
};

export default ClearCache;
