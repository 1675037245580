const getDate = (date) => {
  if (date?.split("-")?.length !== 6) date = "2017-07-29-09-00-pm";

  const [year, month, day, hour, minute, meridiem] = date.split("-");

  let parsedHour = parseInt(hour, 10);
  const isPM = meridiem === "pm";
  if (isPM) {
    parsedHour += 12;
  }

  const extractedDate = new Date(year, month - 1, day, parsedHour, minute);

  const extractedDay = extractedDate.getDate().toString().padStart(2, "0");
  const extractedHour = (extractedDate.getHours() % 12 || 12)
    .toString()
    .padStart(2, "0");
  const extractedMinute = extractedDate
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const extractedMeridian = isPM ? "pm" : "am";

  const monthName = new Intl.DateTimeFormat("en", { month: "long" }).format(
    extractedDate
  );
  const monthNameShort = new Intl.DateTimeFormat("en", {
    month: "short",
  }).format(extractedDate);

  const dayName = new Intl.DateTimeFormat("en", { weekday: "long" }).format(
    extractedDate
  );

  return {
    extractedDate: extractedDate,
    extractedDay: extractedDay,
    extractedHour: extractedHour,
    extractedMinute: extractedMinute,
    extractedMeridian: extractedMeridian,
    monthName: monthName,
    monthNameShort: monthNameShort,
    dayName: dayName,
    year: year,
    yearShort: year.substring(2),
  };
};

export default getDate;
