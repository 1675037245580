import React, { useContext, useEffect } from "react";
import { PopupContext, UserContext } from "../../../context.js";
import { endPointLive } from "../../endPoints.js";
import { postDataWithAuth } from "../../../_api/index.js";

const Photobooth = () => {
  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);

  const saveScore = async (points) => {
    const body = new FormData();
    body.append("game_name", "photobooth");
    body.append("points", points);

    const { score } = await postDataWithAuth({
      body,
      url: endPointLive.saveScore,
      token: user.var.token,
    });

    if (score) {
      const userPoints = user.var.points.map((item) =>
        item.game_name === "photobooth" ? { ...item, points: score } : item
      );

      user.fn((prev) => ({
        ...prev,
        points: userPoints,
        totalPoints: parseInt(prev.totalPoints) + points,
      }));
    }
  };

  useEffect(() => {
    document.title = "Patna Pirates | Photobooth";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: "Photobooth",
      showHeader: true,
    }));

    const handleMessage = (e) => {
      if (e.data?.score) saveScore(e.data.score);
    };

    window.addEventListener("message", handleMessage, false);

    return () => window.removeEventListener("message", handleMessage);
  }, []); // eslint-disable-line

  return (
    <div className="row">
      <iframe
        allow="autoplay; camera; microphone"
        src="https://demo.vehub.live/photobooth/patna_pirates/"
        style={{ height: "80vh" }}
        title="title"
      />
    </div>
  );
};

export default Photobooth;
