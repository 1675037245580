import {
  fbFollow,
  fbIcon,
  instaFollow,
  instaIcon,
  ticketIcon,
  profile,
  twitterFollow,
  twitterIcon,
  youtubeFollow,
  youtubeIcon,
} from "../assets/img";

export const headerMenu = [
  {
    className: "notifaction",
    dialogClassName: "modal-dialog-centered",
    icon: ticketIcon,
    name: "tickets",
    popupClassName: "conatctModal loginPage",
  },
  {
    dialogClassName: "modal-dialog-centered login",
    icon: profile,
    name: "/dashboard/user_account/login",
    popupClassName: "conatctModal loginPage",
    showCloseButtun: true,
  },
];

export const footerMenu = [
  { name: "/dashboard", title: "Home" },
  {
    name: "about",
    title: "About Us",
  },
  {
    name: "/gallery",
    title: "Gallery",
  },
  {
    name: "fixtures/matches",
    title: "Fixtures",
  },
  {
    name: "/squad",
    title: "Squad",
  },
  {
    name: "stats/player",
    title: "Stats",
  },
  {
    name: "merchandise",
    title: "Merchandise",
  },
  {
    name: "tickets",
    title: "Tickets",
  },
  {
    name: "/news_events",
    title: "News & Events",
  },
  {
    name: "sponsor",
    title: "Sponsors & Partners",
  },

  {
    name: "/members_lounge",
    title: "Members Lounge",
  },
  {
    name: "contact",
    title: "Contact us",
  },
  {
    name: "faq",
    title: "FAQ",
  },
  {
    name: "leaderboard",
    title: "Leaderboard",
  },
  {
    name: "blog",
    title: "Blog",
  },
];

export const dashboardHotspots = [
  { name: "merchandise", title: "Merchandise" },
  { name: "/gallery", title: "Gallery" },
  {
    name: "fixtures/matches",
    popupClassName: "gallerySeasoModal loungeModal",
    title: "Fixtures",
  },
  {
    name: "stats/player",
    popupClassName: "gallerySeasoModal loungeModal",
    title: "Stats",
  },
  { name: "sponsor", title: "Sponsor" },
  { name: "/squad", title: "Squad" },
  { name: "/members_lounge", title: "Members Lounge" },
  { name: "contact", title: "Contact Us" },
  { name: "about", title: "About Us" },
  { name: "faq", title: "FAQ" },
  { name: "/news_events", title: "News & Media" },
];

export const galleryHotspots = [
  {
    popupClassName: "gallerySeasoModal",
    name: "season/10/images",
    title: "Season 10",
    id: "10",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/9/images",
    title: "Season 9",
    id: "9",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/8/images",
    title: "Season 8",
    id: "8",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/7/images",
    title: "Season 7",
    id: "7",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/6/images",
    title: "Season 6",
    id: "6",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/5/images",
    title: "Season 5",
    id: "5",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/4/images",
    title: "Season 4",
    id: "4",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/3/images",
    title: "Season 3",
    id: "3",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/2/images",
    title: "Season 2",
    id: "2",
  },
  {
    popupClassName: "gallerySeasoModal",
    name: "season/1/images",
    title: "Season 1",
    id: "1",
  },
];

export const galleryLinks = [
  {
    popupClassName: "gallerySeasoModal",
    link: "https://youtube.com/playlist?list=PLFjhTbxzZDiTsqIO2tYdn9rNEBbUuXPVU&si=FPqAc1zp_HZpB0nr",
    title: "Pirate People",
    id: "1",
  },
  {
    popupClassName: "gallerySeasoModal",
    link: "https://youtube.com/playlist?list=PLFjhTbxzZDiRRFTVZRCnwY-m8g-1A9kS_&si=GRe42zBDAAw6DcYo",
    title: "Pirate Zindagi",
    id: "1",
  },
  {
    popupClassName: "gallerySeasoModal",
    link: "https://www.youtube.com/@patnapirates5670",
    title: "Pirate Youtube",
    id: "1",
  },
  {
    popupClassName: "gallerySeasoModal",
    link: "https://youtube.com/playlist?list=PLFjhTbxzZDiQDLbCDFIRW9mMGQDHuqqTN&si=XtYMyQkQpDfmAU7N",
    title: "Pirate Champs",
    id: "1",
  },
  {
    popupClassName: "gallerySeasoModal",
    link: "https://youtube.com/playlist?list=PLFjhTbxzZDiRKDEQYad3Mq0MxbKznryzN&si=kORPbHIRUaYOB12O",
    title: "Pirate Diaries",
    id: "1",
  },
];

export const newsHotspots = [
  {
    activeTab: 1,
    popupClassName: "gallerySeasoModal",
    name: "digital_media",
    title: "News",
  },
  {
    activeTab: 1,
    name: "media_coverage/season_10",
    popupClassName: "gallerySeasoModal",
    title: "Event",
  },
];

export const contactForm = [
  {
    label: { htmlFor: "name", text: "Name" },
    name: "name",
    placeholder: "Enter your name",
  },
  {
    label: { htmlFor: "email", text: "Enter email address" },
    name: "email",
    placeholder: "Your email address",
  },
  {
    label: { htmlFor: "address", text: "Address" },
    name: "address",
    placeholder: "Your address",
  },
  {
    label: { htmlFor: "comment", text: "Comments" },
    name: "comment",
    placeholder: "Leave a comment here",
    type: "textarea",
  },
];

export const contactFormInput = {
  address: "",
  comment: "",
  email: "",
  name: "",
};

export const userFormInput = {
  login: { email: "", password: "" },
  register: { email: "", name: "", password: "" },
  forgot_password: {
    verify: { email: "" },
    reset: { email: "", password: "", otp: "" },
  },
};

export const userForm = [
  {
    id: "name",
    label: { htmlFor: "name", text: "Your Name" },
    name: "name",
    placeholder: "Enter Your Name",
  },
  {
    id: "email",
    label: { htmlFor: "email", text: "Email Address" },
    name: "email",
    placeholder: "Enter Email Address",
  },
  {
    id: "password",
    label: { htmlFor: "password", text: "Password" },
    name: "password",
    placeholder: "Enter Password",
    type: "password",
  },
  {
    id: "otp",
    label: { htmlFor: "otp", text: "OTP" },
    name: "otp",
    placeholder: "Enter OTP",
    type: "text",
  },
];

export const seasons = [
  { date: "8 Dec 2022", match: "MATCH 132 " },
  { date: "8 Dec 2022", match: "MATCH 124 " },
  { date: "8 Dec 2022", match: "MATCH 120 " },
  { date: "8 Dec 2022", match: "MATCH 113 " },
  { date: "8 Dec 2022", match: "MATCH 103 " },
  { date: "8 Dec 2022", match: "MATCH 100 " },
  { date: "8 Dec 2022", match: "MATCH 95 " },
  { date: "8 Dec 2022", match: "MATCH 89 " },
  { date: "8 Dec 2022", match: "MATCH 82" },
  { date: "8 Dec 2022", match: "MATCH 138" },
  { date: "8 Dec 2022", match: "MATCH 134" },
  { date: "8 Dec 2022", match: "MATCH 114" },
];

export const loungeHotspots = [
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "games/memory-game",
    title: "Games",
  },
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "games/pirates-puzzle",
    title: "Games",
  },

  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "games/pirates-crush",
    title: "Games",
  },
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "games/catching-game",
    title: "Games",
  },
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "spin_wheel",
    title: "Spin Wheel",
  },
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "photobooth",
    title: "Photobooth",
  },
  {
    popupClassName: "gallerySeasoModal loungeModal",
    name: "quiz",
    title: "Quiz",
  },
];

export const pointsFilter = {
  player: [
    { text: "Total Points Scored", value: "total_points_scored" },
    { text: "Successful Raids", value: "successful_raids" },
    { text: "Raid Points", value: "raids_points" },
    { text: "Avg Raid Points", value: "avg_raid_points" },
    { text: "Successful Tackles", value: "successful_tackels" },
    { text: "Tackle Points", value: "tackles_points" },
    { text: "Avg Tackle Points", value: "avg_tackle_points" },
    { text: "Do-Or-Die Raid Points", value: "dodie_raid_points" },
    { text: "Super Raids", value: "super_raids" },
    { text: "Super Tackles", value: "super_tackle" },
    { text: "Super 10s", value: "super_10s" },
    { text: "High 5s", value: "super_5s" },
  ],
  team: [
    { text: "Total Points Scored", value: "total_points_scored" },
    { text: "Total Points Conceded", value: "total_points_conceded" },
    { text: "Avg Points Scored", value: "avg_points_scored" },
    { text: "Successful Raids", value: "successful_raids" },
    { text: "Raid Points", value: "raids_points" },
    { text: "Avg Raid Points", value: "avg_raid_points" },
    { text: "Successful Tackles", value: "successful_tackels" },
    { text: "Tackle Points", value: "tackles_points" },
    { text: "Avg Tackle Points", value: "avg_tackle_points" },
    { text: "Super Raids", value: "super_raids" },
    { text: "Super Tackles", value: "super_tackle" },
    { text: "Do-Or-Die Raid Points", value: "dodie_raid_points" },
    { text: "All Outs Inflicted", value: "all_outs_inflicted" },
    { text: "All Outs Conceded", value: "all_outs_conceded" },
  ],
};

export const seasonFilter = [
  { text: "All Seasons" },
  { text: "Season 10" },
  { text: "Season 9" },
  { text: "Season 8" },
  { text: "Season 7" },
  { text: "Season 6" },
  { text: "Season 5" },
  { text: "Season 4" },
  { text: "Season 3" },
  { text: "Season 2" },
  { text: "Season 1" },
];

export const itemsPerPage = 9;

export const aboutSocialLinks = [
  {
    link: "fb_link",
    icon: fbFollow,
    followers: "628k",
    text: "Facebook Followers",
  },

  {
    link: "insta_link",
    icon: instaFollow,
    followers: "284k",
    text: "Instagram Subscribers",
  },
  {
    link: "ytube",
    icon: youtubeFollow,
    followers: "101k",
    text: "Youtube Followers",
  },
  {
    link: "tw_link",
    icon: twitterFollow,
    followers: "589.5k",
    text: "Twitter Followers",
  },
];

export const contactSocialLinks = [
  {
    link: "fb_link",
    icon: fbIcon,
  },

  {
    link: "insta_link",
    icon: instaIcon,
  },
  {
    link: "ytube",
    icon: youtubeIcon,
  },
  {
    link: "tw_link",
    icon: twitterIcon,
  },
];

export const aboutText = {
  first: [
    "<span>Patna Pirates</span> is the Patna city Franchise and the most successful team in the league with only team to win the title thrice in a row in season 3, 4 & 5 of the Vivo Pro Kabaddi. Pirates have proved themselves as real champions by giving three consecutive wins back to back.",

    "Owned by KVS Energy and Sports Limited, Patna Pirates believes that the spirit of the pirate is the spirit of not letting go, of using the environment to advantage, of striking when the enemy is least aware. It is named not after the criminal meaning of the word but after the Greek word ‘peirin’ which means “to attack”.",

    "With a fan base of 6.31 lakh verifed followers on Facebook, 592k on Twitter 265k on Instagram, Patna Pirates had a fairly successful Season 1 and 2(3rd and 4th position respectively) became Champions of Season 3, Season 4 , season 5 consecutively. Patna Pirates is the only team to have a hattrick win in consecutive seasons.Coached by Ravi Shetty.",

    "KVS Energy and Sports Pvt Limited is owned by Rajesh Shah, Co-Chairman and Managing Director of Mukand Ltd and his son Mr. Kaustubh Vir Shah. Mukand is India’s leading integrated specialty steel producer and a manufacturer of heavy industrial machinery. Mr. Rajesh Shah is a third-generation industrialist who has held several board positions in large Indian Public sector Units as well as multinational companies. He is the Past President of the Confederation of Indian Industries, and the Young Presidents’ Organisation. Mr. Shah is also a keen follower of sport and is himself a fitness enthusiast. Patna Pirates is his first venture into sporting territory.",
  ],

  second: [
    "Unfortunately 6 and 7 were contesting but the team came back in season 8 to almost win the tittle and claim second spot.Season 9 the team will look to create history by claiming it’s 4th title. The Pirates anthem which is penned by the great Prasoon Joshi, put to music by the much loved Late Aadesh Shrivastava and rendered by none other than Kailash Kher has become a big hit not just among the Pirate fans but across all sports fans.",

    "The Pro Kabaddi League (PKL) is promoted by Mashal Sports, a company promoted by Anand Mahindra and Charu Sharma. At the end of Season 1, August 2014, Star Sports acquired a majority stake in Mashal Sports. PKL Season 1 was held on July 2014, Season 2 in July 2015, Season 3 from January 30 - March 6, 2016, Season 4 from June 25 - July 31, 2016, season 5 from July 28 - October 28, 2017, season 6 was held in 2018, season 7 started from July 20 - October 19 2019, season 8 started from December 22 2021 - February 25 2022.",

    " Since its inception in 2014, Vivo Pro Kabaddi League has accumulated the interest of sports devotees throughout India. It has been played across cities and has given many youngsters a platform to mark their skills in the field of kabaddi.",
  ],
};

export const userProfileInputs = [
  { name: "name", placeholder: "Your Name" },
  { name: "email", placeholder: "Your Email Address" },
  // { name: "phone", placeholder: "Your Phone Number" },
];

export const userScoreFixer = [
  {
    desc: "Pirates-Crush Game Points",
    game_name: "pirates-crush",
    points: "0",
  },
  {
    desc: "Pirates-Puzzle Game Points",
    game_name: "pirates-puzzle",
    points: "0",
  },
  { desc: "Memory Game Points", game_name: "memory-game", points: "0" },
  { desc: "Catching Game Points", game_name: "catching-game", points: "0" },
  { desc: "Photobooth Points", game_name: "photobooth", points: "0" },
  { desc: "Quiz Points", game_name: "quiz", points: "0" },
  { desc: "Spin The Wheel Points", game_name: "spin-the-wheel", points: "0" },
];

export const cropperFixer = {
  unit: "px",
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

export const quizQuestionsCount = 5;
