import React, { useContext, useEffect, useState } from "react";
// import Accordion from "react-bootstrap/Accordion";
import { getData } from "../../_api";
import { endPointLive } from "../endPoints";
import Scrollbar from "../Scrollbar.js";
import { playIcon } from "../../assets/img";
import { PopupContext } from "../../context.js";

const videoTitles = [
  "Kabaddi rules - Points system",
  "Five rules of Pro Kabaddi",
  "Kabaddi rules - Raider & Defender",
  "Kabaddi rules - The raid ft. Rahul",
  "Kabaddi rules - Super raids & Super tackle",
  "Kabaddi rules - The Basics",
  "Five rules of Pro Kabaddi",
];

const Faq = () => {
  const { popupProps } = useContext(PopupContext);

  const [activeVideo, activateVideo] = useState(null);
  const [faq, setFaq] = useState(null);
  const [loading, setLoading] = useState(false);
  const [minFrameHeight, setMinFrameHeight] = useState(null);

  const getFaq = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.faq });
    setLoading(false);
    setFaq(data);
  };

  useEffect(() => {
    getFaq();
    document.title = "Patna Pirates | FAQ";

    popupProps.fn((prev) => ({
      ...prev,
      className: "faqPage",
      heading: "FAQ",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <Scrollbar loading={loading}>
      <div className="row">
        {faq?.video?.map(({ video_url }, key) => (
          <div className="col-lg-3 col-6" key={key}>
            <div className="thumbOuter">
              {activeVideo === key ? (
                <iframe
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  src={`${video_url}?autoplay=1&mute=1}`}
                  title={`video-${key + 1}`}
                  style={{ height: minFrameHeight }}
                />
              ) : (
                <img
                  alt=""
                  src={`https://img.youtube.com/vi/${
                    video_url.split("/")[video_url.split("/").length - 1]
                  }/hqdefault.jpg`}
                  style={{ maxHeight: 340 }}
                  onLoad={({ target }) => {
                    if (!minFrameHeight) setMinFrameHeight(target.height);
                  }}
                />
              )}
              {activeVideo !== key && (
                <span className="playIcon" onClick={() => activateVideo(key)}>
                  <img src={playIcon} alt="" />
                </span>
              )}
              <div
                className={`video-title${activeVideo === key ? " hide" : ""}`}
              >
                {videoTitles[key]}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <Accordion>
        {faq?.question?.map(({ answer, question }, key) => (
          <Accordion.Item eventKey={key} key={key}>
            <Accordion.Header>{question}</Accordion.Header>
            <Accordion.Body>{answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion> */}
    </Scrollbar>
  );
};

export default Faq;
