import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PlayersContext, StatsContext } from "../../context.js/index.js";
import { seasonFilter } from "../../utils/fixer.js";
import { Tab, Tabs } from "react-bootstrap";
import { getData } from "../../_api/index.js";
import { endPointLive } from "../endPoints.js";
import Scrollbar from "../Scrollbar.js/index.js";
import { closeIcon } from "../../assets/img/index.js";

const PlayerDetail = () => {
  const navigate = useNavigate();

  const { playerId } = useParams();

  const { close } = useOutletContext();

  const { players } = useContext(PlayersContext);
  const { statsData } = useContext(StatsContext);

  const [seasonFilterForPlayer, setSeasonFilterForPlayer] =
    useState(seasonFilter);
  const [filter, setFilter] = useState(seasonFilter[0].text);
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState({});
  const [playerStats, setPlayerStats] = useState([]);

  const handleSelect = ({ target }) => {
    const { value } = target;
    setFilter(value);
    filterBySeason(value);
  };

  const getStats = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.statsList });
    setLoading(false);
    for (let key in data) {
      if (!data[key].length) return;
    }
    if (data)
      statsData.fn({ player: data.playerstatslist, team: data.teamstatslist });
  };

  const handleSeasonFilter = (stats) => {
    const tempSeasonFilter = stats.map(({ season_name }) => ({
      text: season_name,
    }));

    setSeasonFilterForPlayer(
      tempSeasonFilter.length === 1
        ? tempSeasonFilter
        : [seasonFilter[0], ...tempSeasonFilter]
    );
    setFilter(tempSeasonFilter[0]);
  };

  const addAllStats = (stats = playerStats, tempPlayer = player) => {
    const accumulatedData = {};

    stats.forEach((season) => {
      for (const key in season) {
        if (
          key !== "id" &&
          key !== "season_name" &&
          key !== "player_team" &&
          key !== "player_name" &&
          key !== "player_category" &&
          key !== "created_at" &&
          key !== "updated_at" &&
          key !== "player_id" &&
          key !== "picture" &&
          key !== "stats_section"
        ) {
          if (key.includes("avg") || key.includes("percentage")) {
            const currentValue = parseFloat(season[key]);
            const existingValue = parseFloat(accumulatedData[key] || 0);
            if (!isNaN(currentValue)) {
              accumulatedData[key] = (
                (currentValue + existingValue) /
                2
              ).toFixed(2);
            }
          } else {
            const currentValue = parseInt(season[key]);
            const existingValue = parseInt(accumulatedData[key] || 0);
            if (!isNaN(currentValue)) {
              accumulatedData[key] = currentValue + existingValue;
            }
          }
        } else accumulatedData[key] = season[key];
      }
    });

    setPlayer({ ...tempPlayer, ...accumulatedData });
  };

  const filterBySeason = (seasonName = filter) => {
    if (seasonName === seasonFilter[0].text) {
      addAllStats();
      return;
    }
    const tempPlayerStatsForSeason = playerStats.find(
      ({ season_name }) => season_name === seasonName
    );

    if (tempPlayerStatsForSeason) {
      setPlayer({ ...player, ...tempPlayerStatsForSeason });
    } else navigate("/squad");
  };

  useEffect(() => {
    if (players && statsData.var) {
      const tempPlayer = players.find(({ id }) => id === parseInt(playerId));
      if (tempPlayer) {
        const tempPlayerStats = statsData.var.player.filter(
          (player) => player.player_name === tempPlayer.player_name
        );

        if (tempPlayerStats.length) {
          setPlayerStats(tempPlayerStats);
          handleSeasonFilter(tempPlayerStats);

          if (tempPlayerStats.length === 1)
            setPlayer({ ...tempPlayer, ...tempPlayerStats[0] });
          else addAllStats(tempPlayerStats, tempPlayer);
        } else {
          setPlayer(tempPlayer);
        }
      }
    }
  }, [playerId, players, statsData.var]); // eslint-disable-line

  useEffect(() => {
    if (!statsData.var) getStats();
  }, []); // eslint-disable-line

  return (
    <>
      <button type="button" className="btn-close" onClick={close}>
        <img src={closeIcon} alt="" />
      </button>
      <Scrollbar loading={loading}>
        <div className="playerDetailsOuter">
          <div className="playerInfo">
            <div className="playerImageSec">
              <img src={player.player_image} alt="" />
              <div className="playerName"> {player.player_name} </div>
            </div>
            <div className="playerinformation">
              <div>Team</div>
              <div>Patna Pirates</div>
            </div>
            <div className="playerinformation">
              <div>Born</div>
              <div>{player.dob}</div>
            </div>
            <div className="playerinformation">
              <div>Jersey Number</div>
              <div>{player.jersey_no}</div>
            </div>
            <div className="playerinformation">
              <div>Position</div>
              <div>{player.section}</div>
            </div>
            <div className="playerinformation">
              <div>Nationality</div>
              <div>{player.nationality}</div>
            </div>
          </div>
          <div className="playerStatsDetails">
            <div className="playerStatsHeader" style={{ marginLeft: 10 }}>
              <h1 className="modal-title">Player Stats</h1>
              {!!playerStats?.length && (
                <div className="filterSec">
                  <div className="form-group">
                    <select
                      className="form-select"
                      onChange={handleSelect}
                      value={filter}
                    >
                      {seasonFilterForPlayer.map(({ text }, key) => (
                        <option key={key}>{text}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="blackheader">
              <div className="row justify-content-center">
                <div className="col-lg-4 text-center bdrRight">
                  <div className="">{player.total_match_played || 0}</div>
                  <p>Match Played</p>
                </div>
                <div className="col-lg-4 text-center bdrRight">
                  <div className="">{player.total_points || 0}</div>
                  <p>Total Points Earned</p>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="">{player.most_points_in_a_match || 0}</div>
                  <p>Most Points In A Match</p>
                </div>
              </div>
            </div>
            <div className="gallerySeasoModal">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 statsTabs"
              >
                <Tab eventKey="home" title="ATTACKING">
                  <div className="dataDetails player-data">
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.total_raids}</div>
                          <p>Total Raids</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.successful_raids_percentage || 0}</div>
                          <p>Successful Raids %</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.super_raids || 0}</div>
                          <p>No of Super Raids</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dataDetails player-data">
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.raids_points || 0}</div>
                          <p>Total Raid Points</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.super_10s || 0}</div>
                          <p>Super 10's</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.avg_raid_points || 0}</div>
                          <p>Average Raid Points</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dataDetails player-data">
                    <div className="row  jc-c">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.successful_raids || 0}</div>
                          <p>Successful Raids</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="DEFENSIVE">
                  <div className="dataDetails player-data">
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.tackles_points || 0}</div>
                          <p>Tackles</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.successful_tackles_percentage || 0}</div>
                          <p>Successful Tackles %</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.super_tackle || 0}</div>
                          <p>No of Super Tackles</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dataDetails player-data">
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.tackles_points || 0}</div>
                          <p>Total Tackle Points</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.super_5s || 0}</div>
                          <p>High 5's</p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.avg_tackle_points || 0}</div>
                          <p>Average Tackle Points</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dataDetails player-data">
                    <div className="row  jc-c">
                      <div className="col-lg-4">
                        <div className="attackingData">
                          <div>{player.successful_tackles || 0}</div>
                          <p>Successful Tackles</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Scrollbar>
    </>
  );
};

export default PlayerDetail;
