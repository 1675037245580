import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import {
  FixturesContext,
  PlayersContext,
  PopupContext,
  ScoreContext,
  TeamsContext,
} from "../../../context.js";
import { getData } from "../../../_api";
import { endPointLive } from "../../endPoints";
import { seasonFilter } from "../../../utils/fixer";
import getDate from "../../date";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TopPerformers } from "./TopPerformers";
import Scrollbar from "../../Scrollbar.js";

export default function Fixtures() {
  const { fixtures } = useContext(FixturesContext);
  const { players } = useContext(PlayersContext);
  const { popupProps } = useContext(PopupContext);
  const { scoreList } = useContext(ScoreContext);
  const { teams } = useContext(TeamsContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { currPage, matchNo, tab } = useParams();

  const [filter, setFilter] = useState("Season 10");
  const [filteredFixtures, filterFixtures] = useState([]);
  const [filteredScore, filterScore] = useState([]);
  const [loading, setLoading] = useState(0); // eslint-disable-line
  const [match, selectMatch] = useState(matchNo);
  const [maxContentWidth, setMaxContentWidth] = useState(0);
  const [paginatedData, paginateData] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [topPerformers, setTopPerformers] = useState(null);

  const tBodyRef = useRef(null);

  const filterFixturesBySeason = (season) => {
    const tempFilteredFixtures = fixtures.var.filter(
      (item) => season === item?.season_name
    );
    filterFixtures(tempFilteredFixtures);
  };

  const filterScoreBySeason = (season) => {
    const tempFilteredScore = scoreList.var.filter(
      (item) => season === item?.season_name
    );

    filterScore(
      getSortedData(
        tempFilteredScore,
        parseInt(season.split(" ")[1]) < 8 ? "total_points" : "rank"
      )
    );
  };

  const setCurrPage = (page) => {
    navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}/${page}`);
    selectMatch(matchNo);
  };

  const handleSelectSeason = ({ target }) => {
    if (loading) return;
    const { value } = target;
    setFilter(value);

    (tab === "matches" ? filterFixturesBySeason : filterScoreBySeason)(value);
    if (!!currPage) setCurrPage(0);
  };

  const onSelectTab = (tab) =>
    navigate(
      `${pathname.substring(0, pathname.indexOf("fixtures") + 9)}${tab}${
        tab === "matches" ? "/0" : ""
      }`
    );

  const getDateNTime = (date) => {
    const {
      extractedDay,
      extractedHour,
      extractedMinute,
      extractedMeridian,
      monthName,
      dayName,
    } = getDate(date);

    return (
      <div className="position-relative dayOuter">
        <span className="date">{extractedDay}</span>
        <div className="dateTitme">
          {monthName}
          <span>{`${dayName} | ${extractedHour} : ${extractedMinute}${extractedMeridian}`}</span>
        </div>
      </div>
    );
  };

  const getFixtures = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.fixtures });
    setLoading(false);

    let tempFixtures = data.map((fixture) => {
      const otherTeam = teams.find(
        ({ id }) => id.toString() === fixture.team_name
      );

      const firstTeam = {};
      const secondTeam = {};

      if (otherTeam) {
        if (fixture.is_home === "0") {
          firstTeam.first_team_name = otherTeam.name;
          firstTeam.first_team_logo = otherTeam.logo;
          firstTeam.first_team_score = fixture.other_score;
          secondTeam.second_team_name = "Patna Pirates";
          secondTeam.second_team_logo =
            process.env.REACT_APP_PATNA_PIRATES_LOGO;
          secondTeam.second_team_score = fixture.patna_score;
        } else {
          firstTeam.first_team_name = "Patna Pirates";
          firstTeam.first_team_logo = process.env.REACT_APP_PATNA_PIRATES_LOGO;
          firstTeam.first_team_score = fixture.patna_score;
          secondTeam.second_team_name = otherTeam.name;
          secondTeam.second_team_logo = otherTeam.logo;
          secondTeam.second_team_score = fixture.other_score;
        }
      }

      return {
        ...fixture,
        ...firstTeam,
        ...firstTeam,
        ...secondTeam,
      };
    });
    tempFixtures = tempFixtures.sort(
      (a, b) => parseInt(a.match_no) - parseInt(b.match_no)
    );
    if (tempFixtures) fixtures.fn(tempFixtures);
  };

  const getSortedData = (scoreList, sortBy) =>
    scoreList.sort((a, b) => {
      const pointsTypeA = parseFloat(a[sortBy]);
      const pointsTypeB = parseFloat(b[sortBy]);
      return sortBy === "rank"
        ? pointsTypeA - pointsTypeB
        : pointsTypeB - pointsTypeA;
    });

  const getScoreList = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.scoreList });
    setLoading(false);
    if (data?.length) {
      scoreList.fn(
        data.map((team) => ({
          ...team,
          ...teams.find(({ id }) => id === parseInt(team.team_name)),
        }))
      );
    }
  };

  const onSelectMatch = () => {
    if (
      match !== null &&
      match !== undefined &&
      players &&
      paginatedData.length &&
      !topPerformers
    ) {
      const topDefender = players.find(
        ({ id }) => id === parseInt(paginatedData[match].top_defender)
      );
      const topRider = players.find(
        ({ id }) => id === parseInt(paginatedData[match].top_rider)
      );

      if (!topDefender || !topRider) return;

      setTopPerformers({
        raidsAttempted: paginatedData[match].raidsattempted,
        raidPoints: paginatedData[match].raidpoints,
        tacklesAttempted: paginatedData[match].tacklesattempted,
        tacklePoints: paginatedData[match].tacklepoints,
        topDefender: topDefender,
        topRider: topRider,
      });

      if (!matchNo) navigate(`${pathname}/${match}`);
    } else if (!matchNo) {
      setTopPerformers(null);
      selectMatch(matchNo);
    }
  };

  useEffect(() => {
    if (filteredScore.length) {
      let tempRanks = [1];

      for (let index = 1; index < filteredScore.length; index++) {
        if (filteredScore[index])
          tempRanks.push(
            filteredScore[index].total_points ===
              filteredScore[index - 1].total_points
              ? tempRanks[index - 1]
              : tempRanks[index - 1] + 1
          );
      }
      setRanks(tempRanks);
    }
  }, [filter, filteredScore, tab]);

  useEffect(onSelectMatch, [match, players, paginatedData]); // eslint-disable-line

  useEffect(() => {
    if (ranks.length) {
      setMaxContentWidth(0);
      setTimeout(() => {
        const rows = tBodyRef.current?.childNodes || [];

        let tempMaxWidth = 0;
        for (let ind = 0; ind < rows.length; ind++) {
          if (tempMaxWidth < rows[ind]?.childNodes[1]?.firstChild?.clientWidth)
            tempMaxWidth = rows[ind]?.childNodes[1]?.firstChild?.clientWidth;
        }
        setMaxContentWidth(tempMaxWidth);
      }, 200);
    }
  }, [ranks]);

  useEffect(() => {
    if (teams && tab === "matches") {
      if (!fixtures.var) getFixtures();
      else filterFixturesBySeason(filter);
    }
  }, [fixtures.var, teams, tab]); // eslint-disable-line

  useEffect(() => {
    if (teams && tab === "teams_standing") {
      if (!scoreList.var) getScoreList();
      else filterScoreBySeason(filter);
    }
  }, [scoreList.var, teams, tab]); // eslint-disable-line

  useEffect(() => {
    if (!tab || (tab === "matches" && !currPage)) navigate("matches/0");
  }, [tab, currPage]); // eslint-disable-line

  useEffect(() => {
    document.title = "Patna Pirates | Fixtures";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal loungeModal fixturesModal",
      heading: "Fixtures",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return matchNo && topPerformers ? (
    <TopPerformers performers={topPerformers} />
  ) : (
    <>
      <div className="filterSec">
        <div className="form-group">
          <select
            className="form-select"
            onChange={handleSelectSeason}
            value={filter}
          >
            {seasonFilter.map(
              ({ text }, key) => !!key && <option key={key}>{text}</option>
            )}
          </select>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Tabs
            activeKey={tab}
            className="mb-3 statsTabs"
            onSelect={onSelectTab}
          >
            <Tab eventKey="matches" title="Fixtures">
              {tab === "matches" && (
                <Scrollbar
                  loading={loading}
                  color="#fff"
                  pagination={{
                    data: filteredFixtures,
                    dependencies: [filteredFixtures, filter, currPage],
                    currPage: parseInt(currPage),
                    setCurrPage: setCurrPage,
                    paginateData: paginateData,
                  }}
                >
                  <div className="mediaOuter">
                    <div className="row">
                      {paginatedData.map((fixture, key) => (
                        <div
                          className="col-md-6 col-lg-4 fixture-match"
                          key={key}
                        >
                          <div className="resultMatchs">
                            <div className="resultTitle">
                              {getDateNTime(fixture.date_time)}
                              <div className="matchNo">
                                MATCH NO. : {fixture.match_no}
                              </div>
                            </div>
                            <div
                              className="matchesTeams"
                              onClick={() => selectMatch(key)}
                            >
                              <div className="matchBetween">
                                <span>
                                  {" "}
                                  <img
                                    src={fixture.first_team_logo}
                                    className="matchimage"
                                    alt=""
                                  />
                                </span>
                                <div>
                                  {fixture.first_team_score} <span> - </span>{" "}
                                  {fixture.second_team_score}{" "}
                                </div>
                                <span>
                                  {" "}
                                  <img
                                    src={fixture.second_team_logo}
                                    className="matchimage"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="teamsName">
                                {fixture.first_team_name} <span>Vs</span>{" "}
                                {fixture.second_team_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Scrollbar>
              )}
            </Tab>
            <Tab eventKey="teams_standing" title="Teams Standing">
              {tab === "teams_standing" && (
                <Scrollbar loading={loading} color="#fff">
                  <div className="tableOuter">
                    <div className="display-shine"></div>
                    <div className="display-monitor"></div>
                    <div className="display">
                      {!!filteredScore?.length && (
                        <table className="table-responsive table">
                          <thead>
                            <tr>
                              <th>RANK</th>
                              <th>
                                {" "}
                                <span
                                  style={{
                                    width: maxContentWidth + 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "0 auto",
                                    alignItems: "center",
                                  }}
                                >
                                  Team
                                </span>
                              </th>
                              <th>Matches Played</th>
                              <th>Won</th>
                              <th>Lost</th>
                              <th>Draw</th>
                              <th>Points</th>
                            </tr>
                          </thead>
                          <tbody ref={tBodyRef}>
                            {filteredScore?.map((team, index) => (
                              <tr key={index}>
                                <td>{team?.rank || ranks[index]}</td>
                                <td>
                                  <span
                                    style={{
                                      width: !!maxContentWidth
                                        ? maxContentWidth + 20
                                        : "fit-content",
                                      display: "flex",
                                      margin: "0 auto",
                                      textTransform: "capitalize",
                                      textAlign: "left",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      className="list-icon"
                                      src={team.logo}
                                      alt=""
                                    />
                                    {team.name}
                                  </span>
                                </td>
                                <td>{team.total_played}</td>
                                <td>{team.total_won}</td>
                                <td>{team.total_lost}</td>
                                <td>{team.draw}</td>
                                <td>{team.total_points}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </Scrollbar>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
}
