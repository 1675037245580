import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const ButtonLoder = ({ color = "#fff", height = 23, width = 5 }) => (
  <ScaleLoader
    color={color}
    loading={true}
    // css={override}
    height={height}
    width={width}
  />
);

export default ButtonLoder;
