import React, { useContext, useEffect, useRef, useState } from "react";
import { PopupContext, UserContext } from "../../../context.js";
import { userProfileInputs } from "../../../utils/fixer";
import { avatar, logoutIcon } from "../../../assets/img/index";
import ImageCropper from "./ImageCropper";
import Scrollbar from "../../Scrollbar.js/index.js";

export default function UserProfile() {
  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);

  const [fileInput, setFileInput] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);

  const uploadBtnRef = useRef(null);

  const logout = () => {
    user.fn({});
  };

  const handleChangeFile = ({ target }) => {
    if (target.files.length) {
      let file = URL.createObjectURL(target.files[0]);
      setUploadedImage(file);
    }
  };

  useEffect(() => {
    document.title = "Patna Pirates | User Profile";
    popupProps.fn((prev) => ({
      ...prev,
      dialogClassName: "editProfilePage",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <>
      <div className="editPageOuter">
        <div className="editFormSec">
          <div className="shapeBox">
            <div className="headingMain">
              <span>Profile</span>
            </div>

            <span className="profileImage">
              <img
                style={{ borderRadius: "50%" }}
                src={user.var.image || avatar}
                alt="logout"
              />
              <span
                className="editIcon"
                onClick={() => uploadBtnRef.current.click()}
              />
              {fileInput && (
                <input
                  accept=".jpg,.jpeg,.png,.jfif,.webp"
                  hidden
                  name="myImage"
                  onChange={handleChangeFile}
                  ref={uploadBtnRef}
                  type="file"
                />
              )}
            </span>
            <span className="logout-btn" onClick={logout}>
              <img src={logoutIcon} alt="logout" />
              <span>Logout</span>
            </span>
          </div>
          {!!uploadedImage && (
            <ImageCropper
              image={uploadedImage}
              setImage={setUploadedImage}
              setFileInput={setFileInput}
            />
          )}
          <div className="formSec" style={{ position: "relative" }}>
            <div className="formOuter">
              <Scrollbar>
                <form>
                  <div className="row">
                    {userProfileInputs.map((input, index) => (
                      <div className="col-lg-12" key={index}>
                        <div className="form-floating">
                          <input
                            className="form-control"
                            disabled={index < 2}
                            id={input.name}
                            name={input.name}
                            onChange={() => {}}
                            placeholder={input.placeholder}
                            style={index < 2 ? { border: "none" } : {}}
                            value={index < 2 ? user.var[input.name] : ""}
                          />
                          <label htmlFor={input.placeholder}>
                            {input.placeholder}
                          </label>
                        </div>
                      </div>
                    ))}

                    {/* <div className="col-lg-12 text-center">
                      <button className="btn send" disabled={!!uploadedImage}>
                        {" "}
                        Submit{" "}
                      </button>
                    </div> */}
                  </div>
                  {!!uploadedImage && <div className="disable-form" />}
                </form>
              </Scrollbar>
            </div>
          </div>
        </div>
        <div className="myScore">
          <h2>
            My Score
            <span className="totalSocre">
              Total Points : {user.var.totalPoints}{" "}
            </span>
          </h2>

          <Scrollbar>
            <div className="scoreListingOuter">
              {user.var.points?.map((item, key) => (
                <div className="scoreList" key={key}>
                  <span
                    className="userImage"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_PATNA_PIRATES_LOGO})`,
                    }}
                  ></span>
                  <div>
                    <p>{item.desc}</p>
                  </div>
                  <span className="points">{item.points}</span>
                </div>
              ))}
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
}
