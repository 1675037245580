import React, { useContext, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { cropperFixer } from "../../../utils/fixer";
import "react-image-crop/dist/ReactCrop.css";
import { postDataWithAuth } from "../../../_api";
import { endPointLive } from "../../endPoints";
import { UserContext } from "../../../context.js";
import { toast } from "react-toastify";
import ButtonLoder from "../../ButtonLoader";

const ImageCropper = ({ image, setFileInput, setImage }) => {
  const { user } = useContext(UserContext);

  const [crop, setCrop] = useState(cropperFixer);
  const [croppedImage, cropImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cropperHeight, setCropperHeight] = useState(0);

  const containerRef = useRef(null);

  const resetFileInput = () => {
    setImage(null);
    setFileInput(false);

    setTimeout(() => {
      setFileInput(true);
    }, 200);
  };

  const uploadImage = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("image", croppedImage);

    const { errors, message, success } = await postDataWithAuth({
      body,
      url: endPointLive.uploadImage,
      token: user.var.token,
    });
    setLoading(false);
    if (errors) {
      for (let key in errors) {
        toast.error(errors[key][0]);
        break;
      }
    } else if (success) {
      toast.success(message);
      user.fn((prev) => ({ ...prev, image: croppedImage }));
      resetFileInput();
    }
  };

  const handleCropImage = (img = image2) => {
    const canvas = document.createElement("canvas");
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    cropImage(base64Image);
  };

  useEffect(() => {
    if (containerRef.current)
      setCropperHeight(containerRef.current.clientWidth);
  }, [containerRef]);

  return (
    <div className="crop-container" ref={containerRef}>
      <ReactCrop
        aspect={1}
        crop={crop}
        keepSelection={true}
        onChange={(c) => setCrop(c)}
        onDragEnd={() => handleCropImage()}
        style={{ height: cropperHeight }}
      >
        <img
          src={image}
          alt="crop"
          onLoad={({ target }) => {
            setImage2(target);
            handleCropImage(target);
          }}
          style={{ maxHeight: cropperHeight }}
        />
      </ReactCrop>
      <div style={{ display: "flex" }}>
        <button
          className="btn"
          disabled={loading}
          style={{ marginTop: 20 }}
          type="button"
          onClick={resetFileInput}
        >
          Cancel
        </button>

        <button
          className="btn"
          disabled={loading}
          style={{ marginTop: 20 }}
          type="button"
          onClick={uploadImage}
        >
          {loading ? <ButtonLoder /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default ImageCropper;
