import React from "react";
import Lightbox from "react-18-image-lightbox";
import "react-image-lightbox/style.css";

const LightboxComponent = ({ media = [], index = null, setIndex }) =>
  index >= 0 && media?.length ? (
    <Lightbox
      mainSrc={media[index]}
      nextSrc={media[(index + 1) % media.length]}
      prevSrc={media[(index + media.length - 1) % media.length]}
      onCloseRequest={() => setIndex(null)}
      onMovePrevRequest={() =>
        setIndex((index + media.length - 1) % media.length)
      }
      onMoveNextRequest={() => setIndex((index + 1) % media.length)}
    />
  ) : null;

export default LightboxComponent;
