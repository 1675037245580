export const endPointLocal = {
  login: `${process.env.REACT_APP_API_URL_LIVE}/login`,
  register: `${process.env.REACT_APP_API_URL_LIVE}/register`,
  teamList: `${process.env.REACT_APP_API_URL_LIVE}/team_list`,
  playerList: `${process.env.REACT_APP_API_URL_LIVE}/player_list`,
  statsList: `${process.env.REACT_APP_API_URL_LIVE}/stats_list`,
};

export const endPointLive = {
  enquiry: `${process.env.REACT_APP_API_URL_LIVE}/submit_enquiry`,
  faq: `${process.env.REACT_APP_API_URL_LIVE}/faq`,
  fixtures: `${process.env.REACT_APP_API_URL_LIVE}/teamfixture`,
  forgot_password: {
    reset: `${process.env.REACT_APP_API_URL_LIVE}/update_password`,
    verify: `${process.env.REACT_APP_API_URL_LIVE}/forgot_password`,
  },
  galleryImages: `${process.env.REACT_APP_API_URL_LIVE}/gallery_category`,
  galleryVideos: `${process.env.REACT_APP_API_URL_LIVE}/video_list`,
  info: `${process.env.REACT_APP_API_URL_LIVE}/global_data`,
  leaderboard: `${process.env.REACT_APP_API_URL_LIVE}/leaderboard`,
  login: `${process.env.REACT_APP_API_URL_LIVE}/login`,
  news: `${process.env.REACT_APP_API_URL_LIVE}/news_media`,
  quizQuestions: `${process.env.REACT_APP_API_URL_LIVE}/game_question`,
  quizAnswer: `${process.env.REACT_APP_API_URL_LIVE}/check_question`,
  playerList: `${process.env.REACT_APP_API_URL_LIVE}/player_list`,
  register: `${process.env.REACT_APP_API_URL_LIVE}/register`,
  sponsors: `${process.env.REACT_APP_API_URL_LIVE}/partners_list`,
  statsList: `${process.env.REACT_APP_API_URL_LIVE}/stats_list`,
  teamList: `${process.env.REACT_APP_API_URL_LIVE}/team_list`,
  teamStanding: `${process.env.REACT_APP_API_URL_LIVE}/team_standinglist`,
  saveScore: `${process.env.REACT_APP_API_URL_LIVE}/save_score`,
  scoreList: `${process.env.REACT_APP_API_URL_LIVE}/score_list`,
  uploadImage: `${process.env.REACT_APP_API_URL_LIVE}/image_update`,
  // usersList: `${process.env.REACT_APP_API_URL_LIVE}/users_list`,
};
