export const validateAddress = (address) => {
  if (!address) return "Address is required";
  else if (address.length < 10) return "Please enter full address";
  return null;
};

export const validateComment = (comment) => {
  if (!comment) return "Comment is required";
  else if (comment.length < 50)
    return "Comment should be of min. 50 characters";
  return null;
};

export const validateEmail = (email) => {
  if (!email) return "Email is required";
  else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))
    return "Invalid email";
  return null;
};

export const validateName = (name) => {
  if (!name) return "Name is required";
  else if (!/^[a-zA-Z ]*$/.test(name) || name.length < 2) return "Invalid name";
  return null;
};

export const validateOTP = (otp) => {
  if (!otp) return "OTP is required";
  else if (otp.length !== 4) return "OTP should be of 4 digits";
  return null;
};

export const validatePassword = (password) => {
  if (!password) return "Password is required";
  else if (password.length < 8)
    return "Password should be of min. 8 characters";
  return null;
};

export const validation = {
  address: validateAddress,
  comment: validateComment,
  email: validateEmail,
  name: validateName,
  otp: validateOTP,
  password: validatePassword,
};

export default validation;
