import React, { useContext, useEffect } from "react";
import { PopupContext } from "../../context.js";

const Merchandise = () => {
  const { popupProps } = useContext(PopupContext);

  useEffect(() => {
    document.title = `Patna Pirates | Merchandise`;

    popupProps.fn((prev) => ({
      ...prev,
      className: "coming-soon",
      heading: "Merchandise",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return <></>;
};

export default Merchandise;
