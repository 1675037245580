import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PopupContext, UserContext } from "../../../context.js";
import { endPointLive } from "../../endPoints.js";
import { getDataWithAuth, postDataWithAuth } from "../../../_api/index.js";
import Scrollbar from "../../Scrollbar.js/index.js";
import ButtonLoder from "../../ButtonLoader.js";
import { quizQuestionsCount } from "../../../utils/fixer.js";
import { p2Left } from "../../../assets/img/index.js";

const keyChar = {
  1: "a",
  2: "b",
  3: "c",
  4: "d",
};

const Quiz = () => {
  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [ansLoading, setAnsLoading] = useState(false);
  const [activeQuestion, activateQuestion] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [score, setScore] = useState(0);
  const [scoreToShow, setScoreToShow] = useState(0);
  const [selectedAnswer, selectAnswer] = useState(null);
  const [result, setResult] = useState(null);

  const reset = () => {
    setAnsLoading(false);
    activateQuestion(1);
    setScore(0);
    setScoreToShow(0);
    selectAnswer(null);
    setResult(null);
    getQuestions();
  };

  const setRandomQuestions = (questions = allQuestions) => {
    const shuffled = questions.sort(() => 0.5 - Math.random());
    setQuestions(shuffled.slice(0, quizQuestionsCount));
  };

  const getQuestions = async () => {
    setLoading(true);
    const { data } = await getDataWithAuth({
      url: endPointLive.quizQuestions,
      token: user.var.token,
    });

    setLoading(false);

    if (data?.length >= quizQuestionsCount) {
      setAllQuestions(data);
      if (data.length === quizQuestionsCount) setQuestions(data);
      else setRandomQuestions(data);
    }
  };

  const saveScore = async (points) => {
    const gamePoints = user.var.points.find(
      ({ game_name }) => game_name === "quiz"
    ).points;

    setScoreToShow(points);
    activateQuestion(null);

    if (parseInt(gamePoints) > points) return;

    const body = new FormData();
    body.append("game_name", "quiz");
    body.append("points", points);

    const { score } = await postDataWithAuth({
      body,
      url: endPointLive.saveScore,
      token: user.var.token,
    });
    setAnsLoading(false);

    if (score) {
      const userPoints = user.var.points.map((item) =>
        item.game_name === "quiz" ? { ...item, points: score } : item
      );

      user.fn((prev) => ({
        ...prev,
        points: userPoints,
        totalPoints: parseInt(prev.totalPoints) + (points - gamePoints),
      }));
    }
  };

  const checkAnswer = async () => {
    if (ansLoading || !!result || !selectedAnswer) return;
    setAnsLoading(true);
    const body = new FormData();
    body.append("question", activeQuestion);
    body.append("answer", selectedAnswer);

    const { errors, message, success } = await postDataWithAuth({
      body,
      url: endPointLive.quizAnswer,
      token: user.var.token,
    });

    setAnsLoading(activeQuestion === questions.length);

    if (errors) {
      toast.error(errors);
      user.fn({});
      navigate("/dashboard/user_account/login");
    } else if (success) {
      setResult(message);
      let tempScore = score;
      if (!message.includes("In")) {
        tempScore = score + 5;
      }
      setScore(tempScore);
      setTimeout(() => {
        if (activeQuestion === questions.length) saveScore(tempScore);
        else activateQuestion((prev) => prev + 1);
        selectAnswer(null);
        setResult(null);
      }, 1000);
    }
  };

  useEffect(() => {
    document.title = "Patna Pirates | Quiz";

    popupProps.fn((prev) => ({
      ...prev,
      className: "quizModal",
      showHeader: true,
      heading: "Quiz",
    }));

    if (!scoreToShow) getQuestions();
  }, []); // eslint-disable-line

  return (
    <Scrollbar loading={loading}>
      <div className="quizSec">
        <Row>
          <Col lg="8">
            {!scoreToShow ? (
              <>
                <h2>{questions[activeQuestion - 1]?.question}</h2>
                <Row>
                  {questions[activeQuestion - 1]?.answer?.map((ans, key) => (
                    <Col md={6} key={key + 1}>
                      <div className="form-group">
                        <input
                          id={`ans_${keyChar[key + 1]}`}
                          name="ans"
                          type="radio"
                          checked={selectedAnswer === `ans_${keyChar[key + 1]}`}
                          onChange={() =>
                            selectAnswer(`ans_${keyChar[key + 1]}`)
                          }
                          disabled={!!result || ansLoading}
                        />
                        <label htmlFor={`ans_${keyChar[key + 1]}`}>{ans}</label>
                      </div>
                    </Col>
                  ))}

                  <Col lg={12}>
                    <span
                      style={{
                        color: result?.includes("In") ? "#ff0000" : "#78df4e",
                        marginLeft: 20,
                      }}
                    >
                      {result}
                    </span>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group text-center">
                      <button
                        className="btn send"
                        disabled={ansLoading || !!result || !selectedAnswer}
                        type="button"
                        onClick={checkAnswer}
                      >
                        {ansLoading ? <ButtonLoder /> : "Next"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h2 className="text-center mb-4">
                  THANK YOU FOR PARTICIPATION
                </h2>
                <h3 className="mb-3 text-center" style={{ color: "#78df4e" }}>
                  Your Score is {scoreToShow}
                </h3>
                <div className="form-group text-center mt-4">
                  <button
                    className="btn send"
                    style={{ marginRight: 10 }}
                    type="button"
                    onClick={reset}
                  >
                    Play Again
                  </button>
                  <Link className="btn send" to={-1}>
                    Back
                  </Link>
                </div>
              </>
            )}
          </Col>
          <Col lg="4">
            <div className="manImage">
              <img src={p2Left} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </Scrollbar>
  );
};

export default Quiz;
