import React, { Fragment, useContext, useEffect, useState } from "react";
import { PopupContext, SponsorContext } from "../../context.js/index.js";
import { getData } from "../../_api/index.js";
import { endPointLive } from "../endPoints.js";
import Scrollbar from "../Scrollbar.js/index.js";

const Sponsor = () => {
  const { popupProps } = useContext(PopupContext);
  const { sponsors } = useContext(SponsorContext);

  const [loading, setLoading] = useState(false);

  const getSponsors = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.sponsors });
    setLoading(false);
    if (data) {
      data.sort((a, b) => parseInt(a.order_no) - parseInt(b.order_no));
      sponsors.fn(data);
    }
  };

  useEffect(() => {
    getSponsors();

    document.title = "Patna Pirates | Sponsors";

    popupProps.fn((prev) => ({
      ...prev,
      className: "sponsordModal fixturesModal",
      heading: "Sponsors & Partners",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <Scrollbar loading={loading}>
      {sponsors.var.map(({ extralogo, image, name }, key) => (
        <Fragment key={key}>
          <h2>{name}</h2>
          <div className="row justify-content-md-center">
            {extralogo?.split(",")?.map((logo, key2) => (
              <div className="col-lg-3 col-md-6" key={key2}>
                <div className="sponsorLogos">
                  <div>
                    <img src={logo} alt="" />
                  </div>
                </div>
              </div>
            ))}
            <div className="col-lg-3 col-md-6">
              <div className="sponsorLogos">
                <div>
                  <img src={image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ))}
    </Scrollbar>
  );
};

export default Sponsor;
