import aboutBanner from "./about-banner.png";
import avatar from "./avatar.png";
import avatarDark from "./avatar-dark.png";
import calendar from "./calendar.svg";
import closeIcon from "./close-icon.svg";
import comingSoon from "./coming-soon.png";
import membersLoungeBg from "./engagement-zone.jpg";
import emailIcon from "./mail-icon.svg";
import fbFollow from "./facebook-follow.svg";
import fbIcon from "./facebook-icon.svg";
import galleryBg from "./gallery-bg.jpg";
import greenArrow from "./green-arrow.svg";
import instaFollow from "./instagram-follow.svg";
import instaIcon from "./instagram-icon.svg";
import logo from "./logo.png";
import logoutIcon from "./logout-icon.svg";
import mainBackground from "./main-Background.jpg";
import mapIcon from "./map-icon.svg";
import menuIcon1 from "./menu-icons/menu-1.svg";
import menuIcon2 from "./menu-icons/menu-2.svg";
import menuIcon3 from "./menu-icons/menu-3.svg";
import menuIcon4 from "./menu-icons/menu-4.svg";
import menuIcon5 from "./menu-icons/menu-5.svg";
import menuIcon6 from "./menu-icons/menu-6.svg";
import menuIcon7 from "./menu-icons/menu-7.svg";
import menuIcon8 from "./menu-icons/menu-8.png";
import menuIcon9 from "./menu-icons/menu-9.svg";
import menuIcon10 from "./menu-icons/menu-10.svg";
import menuIcon11 from "./menu-icons/menu-11.svg";
import menuIcon12 from "./menu-icons/menu-12.svg";
import menuIcon13 from "./menu-icons/menu-13.svg";
import menuIcon14 from "./menu-icons/menu-14.svg";
import menuIcon15 from "./menu-icons/menu-15.svg";
import newsEventBg from "./news-event-bg.jpg";
import p2Left from "./p2-left.png";
import playButton from "./play-button.json";
import playerBg from "./player-bg.jpg";
import playIcon from "./play-circle.svg";
import profile from "./profile.svg";
import rightArrow from "./right-arrow.svg";
import rotateIcon from "./rotate-icon.png";
import ticketIcon from "./ticket-icon2.png";
import twitterFollow from "./twitter-follow.svg";
import twitterIcon from "./twitter-icon.svg";
import viewIcon from "./view-icon.svg";
import whiteArrow from "./white-arrow.svg";
import youtubeFollow from "./youtube-follow.svg";
import youtubeIcon from "./youtube-icon.svg";

const menuIcons = [
  menuIcon1,
  menuIcon2,
  menuIcon3,
  menuIcon4,
  menuIcon5,
  menuIcon6,
  menuIcon7,
  menuIcon8,
  menuIcon9,
  menuIcon10,
  menuIcon11,
  menuIcon12,
  menuIcon13,
  menuIcon14,
  menuIcon15,
];

export {
  aboutBanner,
  avatar,
  avatarDark,
  calendar,
  closeIcon,
  comingSoon,
  emailIcon,
  fbFollow,
  fbIcon,
  galleryBg,
  greenArrow,
  instaFollow,
  instaIcon,
  logo,
  logoutIcon,
  mainBackground,
  mapIcon,
  membersLoungeBg,
  menuIcons,
  newsEventBg,
  p2Left,
  playButton,
  playerBg,
  playIcon,
  profile,
  rightArrow,
  rotateIcon,
  ticketIcon,
  twitterFollow,
  twitterIcon,
  viewIcon,
  whiteArrow,
  youtubeIcon,
  youtubeFollow,
};
