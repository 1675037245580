import React from "react";
import { Col, Row } from "react-bootstrap";

export const TopPerformers = ({ performers = {} }) => {
  return (
    <div className="topPerformers">
      <h2>Our Top Performers Of The Match</h2>
      <Row className="align-items-lg-end">
        <Col md={3}>
          <div className="performerPlayer">
            <img src={performers.topDefender.player_image} alt="" />
            <h3 className="plyerName">
              TOP DEFENDER : {performers.topDefender.player_name}
            </h3>
          </div>
        </Col>
        <Col md={6}>
          <div className="pointsTable">
            <ul>
              <li>
                {" "}
                Raids Attempted <span>{performers.raidsAttempted}</span>{" "}
              </li>
              <li>
                Tackles Attempted <span>{performers.tacklesAttempted}</span>
              </li>
              <li>
                Raid Points <span>{performers.raidPoints}</span>
              </li>
              <li>
                Tackle Points <span>{performers.tacklePoints}</span>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={3}>
          <div className="performerPlayer">
            <img src={performers.topRider.player_image} alt="" />
            <h3 className="plyerName">
              TOP RAIDER : {performers.topRider.player_name}
            </h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};
