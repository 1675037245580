import React, { useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { closeIcon, emailIcon, mapIcon } from "../../assets/img";
import { InfoContext, PopupContext } from "../../context.js";

import { contactSocialLinks } from "../../utils/fixer";

const Contact = () => {
  const info = useContext(InfoContext);
  const { popupProps } = useContext(PopupContext);

  const { close } = useOutletContext();

  useEffect(() => {
    document.title = "Patna Pirates | Enquiry";

    popupProps.fn((prev) => ({
      ...prev,
      className: "conatctModal",
    }));
  }, []); // eslint-disable-line

  return (
    <div className="contactOuter" style={{ height: "100%" }}>
      <div className="getintouch">
        <div className="formSec p-0">
          <div
            className="modal-header mb-0 p-0 ps-4"
            style={{ justifyContent: "flex-start" }}
          >
            <h1 className="modal-title ">
              <span>Meet The Pirates</span>
            </h1>
            <button type="button" className="btn-close" onClick={close}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
        </div>
        <div>
          <p>
            We love to hear from you. Our friendly team is always here to
            contact
          </p>
          <address>
            <h3>
              <img src={mapIcon} alt="" /> Address
            </h3>
            {info?.address.split(",")[0]}, <br /> {info?.address.split(",")[1]},
            <br /> {info?.address.split(",")[2]}, {info?.address.split(",")[3]}
          </address>
          <address>
            <h3>
              <img src={emailIcon} alt="" /> Email
            </h3>
            <p>
              <span>CEO : </span>
              {info?.ceo_email}
            </p>
            <p>
              <span>MARKETING & TEAM MANAGER : </span>
              {info?.m_email}
            </p>
            <p>
              <span>MARKETING HEAD : </span>
              {info?.mh_email}
            </p>
          </address>
        </div>

        {!!info && (
          <div className="socailIcons">
            {contactSocialLinks.map(({ icon, link }) => (
              <a href={info[link]} target="_blank" rel="noreferrer">
                <img src={icon} alt="facebook" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
