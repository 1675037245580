import React, { useContext, useEffect } from "react";
import { PopupContext } from "../../context.js";

const Blog = () => {
  const { popupProps } = useContext(PopupContext);

  useEffect(() => {
    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: "Blog",
      showHeader: true,
    }));

    document.title = `Patna Pirates | BLOG`;
  }, []); // eslint-disable-line

  return (
    <div className="row">
      <iframe
        allow="autoplay; camera; microphone"
        src={`https://blog.patnapirates.net/`}
        style={{ height: "80vh", width: "100vw" }}
        title="title"
      />
    </div>
  );
};

export default Blog;
