import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ActionContext, NewsContext, PopupContext } from "../context.js";
import Popup from "../components/modal&components/Modal.js";
import { galleryHotspots, newsHotspots } from "../utils/fixer.js";
import { newsEventBg } from "../assets/img";
import { endPointLive } from "../components/endPoints.js";
import { getData } from "../_api/index.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import useWindowDimensions from "../components/dimensions.js";

const Media = () => {
  const { hfAction } = useContext(ActionContext);
  const { news } = useContext(NewsContext);
  const { popupProps } = useContext(PopupContext);

  const mainRef = useRef(null);

  const { pathname } = useLocation();

  const { season } = useParams();

  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState(false);

  const activateTab = (tab) => {
    const subPathnames = pathname.split("/");
    navigate(`${subPathnames[subPathnames.length - 2]}/${tab}`);
  };

  const handleNavigation = (page = hfAction.var) => {
    navigate(page);
  };

  const handleClosePopup = () => {
    navigate("/news_events");
    hfAction.fn(null);
    popupProps.fn({});
  };

  const getNews = async () => {
    setLoading(true);
    const { data } = await getData({
      url: endPointLive.news,
    });
    setLoading(false);

    news.fn({
      digitalMedia: data.digitalmedia.reverse(),
      mediaCoverages: data.mediacoverages.reverse(),
    });
  };

  useEffect(() => {
    getNews();

    const interval = setInterval(() => {
      if (mainRef.current) {
        if (mainRef.current.scrollWidth > width)
          mainRef.current.scrollLeft =
            (mainRef.current.scrollWidth - width) / 2;
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (pathname.split("/").length - 1 === 1) {
      handleClosePopup();
      document.title = "Patna Pirates | News & Events";
    }
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    if (hfAction.var) handleNavigation();
  }, [hfAction.var]); // eslint-disable-line

  return (
    <div className="wrapper" ref={mainRef}>
      {pathname.split("/").length - 1 > 1 ? (
        <Popup onClose={handleClosePopup} {...popupProps.var}>
          {pathname.includes("season_") ? (
            <Tabs
              activeKey={season}
              className={`mb-3${width < 1428 ? " scroll" : ""}`}
              onSelect={activateTab}
            >
              {galleryHotspots.map(
                ({ title }, index) =>
                  index < 8 && (
                    <Tab
                      eventKey={`season_${10 - index}`}
                      key={index}
                      title={title}
                    >
                      <Outlet
                        context={{
                          close: handleClosePopup,
                          activeTab: { var: season, fn: activateTab },
                          loading: loading,
                          news: news.var.mediaCoverages,
                        }}
                      />
                    </Tab>
                  )
              )}
            </Tabs>
          ) : (
            <Outlet
              context={{
                close: handleClosePopup,
                loading: loading,
                news: news.var.digitalMedia,
              }}
            />
          )}
        </Popup>
      ) : (
        <div className="imageOuter newsEventPageOuter">
          <img src={newsEventBg} alt="" />
          <div className="hotspots">
            {newsHotspots.map((item, index) => (
              <div
                className={`hotspot-item hotspot-item-${index + 1}`}
                key={index}
                name={item.name}
                onClick={() => handleNavigation(item.name)}
              >
                <Link name={item.name}>
                  <span className="pulsating-circle" name={item.name} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
