import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import validation from "../../../utils/validation";
import { endPointLive } from "../../endPoints";
import { getData, postData } from "../../../_api";
import ButtonLoder from "../../ButtonLoader";
import { userForm, userFormInput, userScoreFixer } from "../../../utils/fixer";
import Input from "../../Input";
import { PopupContext, UserContext, UsersContext } from "../../../context.js";
import { closeIcon } from "../../../assets/img";

const UserAccount = () => {
  const { close } = useOutletContext();

  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);
  const users = useContext(UsersContext);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { account } = useParams();

  const [errors, setErrors] = useState({});
  const [isEmailVerified, verifyEmail] = useState(false);
  const [inputs, setInputs] = useState(userFormInput[account]);
  const [isFormValidated, validateForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmitResetPassword = async (e) => {
    e.preventDefault();
    if (!loading && (await validate())) {
      setLoading(true);
      const body = new FormData();
      for (let key in inputs) {
        body.append(key, inputs[key]);
      }

      const { message } = await postData({
        url: [endPointLive[account][isEmailVerified ? "reset" : "verify"]],
        body: body,
      });
      setLoading(false);
      toast[
        message.includes("OTP send") || message.includes("Successfully")
          ? "success"
          : "error"
      ](message);
      if (message.includes("OTP send")) {
        verifyEmail(true);
        validateForm(false);
      } else if (message.includes("Successfully")) {
        navigate("/dashboard/user_account/login");
      }
    }
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    if (!loading && (await validate())) {
      setLoading(true);
      const body = new FormData();
      for (let key in inputs) {
        body.append(key, inputs[key]);
      }
      const { data, errors, message } = await postData({
        url: [endPointLive[account]],
        body: body,
      });
      if (errors) {
        for (let key in errors) {
          toast.error(errors[key][0]);
          break;
        }
      } else {
        if (message) {
          if (typeof data === "object" && Object.keys(data).length) {
            const earnedPoints = users.var.find(
              ({ user_id }) => user_id === data.id
            );
            const allPonits = userScoreFixer.map((item) => ({
              ...item,
              points:
                earnedPoints?.point_details?.find(
                  ({ game_name }) => game_name === item.game_name
                )?.points || item.points,
            }));
            user.fn({
              ...data,
              points: allPonits,
              totalPoints: earnedPoints?.total_points || 0,
            });
            toast.success(message);
            navigate(-1);
          } else toast.warning(message);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onSubmitRegister = async (e) => {
    e.preventDefault();
    if (!loading && (await validate())) {
      setLoading(true);
      const body = new FormData();
      for (let key in inputs) {
        body.append(key, inputs[key]);
      }
      const { errors, message } = await postData({
        url: [endPointLive[account]],
        body: body,
      });
      if (errors) {
        for (let key in errors) {
          toast.error(errors[key][0]);
          break;
        }
      } else {
        toast.success(message);
        navigate("/dashboard/user_account/login");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const validate = async (target) => {
    let error = { ...errors };
    let returnValue = true;
    if (target) {
      const { name, value } = target;
      error[name] = validation[name](value);
    } else {
      validateForm(true);

      for (let key in inputs) {
        error[key] = validation[key](inputs[key]);
        if (returnValue && error[key]) returnValue = !error[key];
      }
    }
    setErrors(error);
    return returnValue;
  };

  const onChange = ({ target }) => {
    let { name, value } = target;
    if (name === "email" || name === "password") value = value.trim();
    else if (name === "otp")
      value = isNaN(value) || value.length > 4 ? inputs[name] : value;

    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    validate({ name, value });
  };

  const handleTab = (tab) => {
    setInputs(userFormInput[tab]);
    setErrors({});
    validateForm(false);
    navigate(`/${pathname.split("/")[1]}/user_account/${tab}`);
  };

  const getUsers = async () => {
    const { data } = await getData({ url: endPointLive.leaderboard });
    if (data?.length) users.fn(data);
  };

  useEffect(() => {
    document.title = `Patna Pirates | ${
      account === "login"
        ? "Login"
        : account === "register"
        ? "Registration"
        : "Reset Password"
    }`;

    setInputs((prev) => ({
      ...(account === "forgot_password"
        ? userFormInput[account][isEmailVerified ? "reset" : "verify"]
        : userFormInput[account]),
      email: prev.email,
    }));
  }, [account, isEmailVerified]);

  useEffect(() => {
    popupProps.fn((prev) => ({
      ...prev,
      className: "conatctModal loginPage",
      dialogClassName: "modal-dialog-centered login",
      showHeader: false,
    }));

    if (!users.var.length) getUsers();
  }, []); // eslint-disable-line

  return (
    <>
      <button type="button" className="btn-close login" onClick={close}>
        <img src={closeIcon} alt="" />
      </button>
      {account === "forgot_password" ? (
        <>
          <h2>Reset your account password</h2>
          <div className="formSec">
            <div className="formOuter">
              <form
                onSubmit={onSubmitResetPassword}
                style={{ position: "relative" }}
              >
                {userForm.map(
                  (inputProps, key) =>
                    (isEmailVerified ? key > 0 : key === 1) && (
                      <Input
                        inputProps={{
                          style: errors[inputProps.name]
                            ? { borderColor: "#ff0000" }
                            : {},
                          disabled:
                            inputProps.name === "email" && isEmailVerified,
                          error: errors[inputProps.name],
                          onChange: onChange,
                          value: inputs[inputProps.name],
                          ...inputProps,
                        }}
                        // resend={inputProps.name === "otp" && isEmailVerified}
                        isFormValidated={isFormValidated}
                        key={key}
                      />
                    )
                )}

                {/* <div className="form-group forgotPassword">
                  <Link to="/dashboard/user_account/login">back to signIn</Link>
                </div> */}
                <div className="form-group">
                  <button className="btn send" disabled={loading}>
                    {" "}
                    {loading ? (
                      <ButtonLoder />
                    ) : isEmailVerified ? (
                      "Reset"
                    ) : (
                      "Get OTP"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <Tabs
          activeKey={account}
          transition={false}
          className="mb-3"
          onSelect={handleTab}
        >
          <Tab eventKey="login" title="Sign In">
            {account === "login" && (
              <>
                <h2>Sign In Your Account</h2>
                <div className="formSec">
                  <div className="formOuter">
                    <form onSubmit={onSubmitLogin}>
                      {userForm.map(
                        (inputProps, key) =>
                          key > 0 &&
                          key < userForm.length - 1 && (
                            <Input
                              inputProps={{
                                style: errors[inputProps.name]
                                  ? { borderColor: "#ff0000" }
                                  : {},
                                error: errors[inputProps.name],
                                onChange: onChange,
                                value: inputs[inputProps.name],
                                ...inputProps,
                              }}
                              isFormValidated={isFormValidated}
                              key={key}
                            />
                          )
                      )}

                      <div className="form-group forgotPassword">
                        <Link to="/dashboard/user_account/forgot_password">
                          Forgot Your Password?
                        </Link>
                      </div>

                      <div className="form-group">
                        <button className="btn send" disabled={loading}>
                          {" "}
                          {loading ? <ButtonLoder /> : "Sign In"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="register" title="New Account">
            {account === "register" && (
              <>
                <h2>Create Your Account</h2>
                <div className="formSec">
                  <div className="formOuter">
                    <form onSubmit={onSubmitRegister}>
                      {userForm.map(
                        (inputProps, key) =>
                          key < userForm.length - 1 && (
                            <Input
                              inputProps={{
                                style: errors[inputProps.name]
                                  ? { borderColor: "#ff0000" }
                                  : {},
                                error: errors[inputProps.name],
                                onChange: onChange,
                                value: inputs[inputProps.name],
                                ...inputProps,
                              }}
                              isFormValidated={isFormValidated}
                              key={key}
                            />
                          )
                      )}

                      <div className="form-group">
                        <button className="btn send" disabled={loading}>
                          {" "}
                          {loading ? <ButtonLoder /> : "Register"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </Tab>
        </Tabs>
      )}
    </>
  );
};

export default UserAccount;
