import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ActionContext,
  PopupContext,
  StatsContext,
  TeamsContext,
  UserContext,
} from "../context.js";
import Popup from "../components/modal&components/Modal.js";
import { loungeHotspots } from "../utils/fixer.js";
import { membersLoungeBg } from "../assets/img";
import { towardsLounge } from "../assets/videos/index.js";
import { getData } from "../_api/index.js";
import { endPointLive } from "../components/endPoints.js";
import Scrollbar from "../components/Scrollbar.js/index.js";
import useWindowDimensions from "../components/dimensions.js";

const exceptionalKeys = [
  "created_at",
  "id",
  "picture",
  "player_category",
  "player_id",
  "player_name",
  "player_team",
  "season_name",
  "stats_section",
  "updated_at",
];

const MembersLounge = () => {
  const { height, width } = useWindowDimensions();
  const mainRef = useRef(null);

  const { hfAction, showHF } = useContext(ActionContext);
  const { popupProps } = useContext(PopupContext);
  const { statsData } = useContext(StatsContext);
  const { teams } = useContext(TeamsContext);
  const { user } = useContext(UserContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [isVideoEnded, endVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);

  const handleNavigation = (page) => {
    navigate(page);
  };

  const handleClosePopup = () => {
    if (pathname.includes("stats")) {
    }
    navigate("/members_lounge");

    hfAction.fn(null);
    popupProps.fn({});
  };

  const onVideoEnded = () => {
    if (user.var.token) {
      endVideo(true);
    } else navigate("/dashboard/user_account/login");
    showHF(true);
  };

  const getSortedData = (stats) =>
    stats.sort((a, b) => {
      const pointsDiff = parseInt(b.total_points) - parseInt(a.total_points);
      if (pointsDiff === 0)
        return parseInt(a.total_match_played) - parseInt(b.total_match_played);
      return pointsDiff;
    });

  const getTopPlayers = () => {
    const aggregatedData = {};

    statsData.var.player.forEach((stats) => {
      const teamId = stats.player_team;
      const id = `${stats.player_name}_${teamId}`;
      if (aggregatedData[id]) {
        Object.keys(stats).forEach((key) => {
          if (!exceptionalKeys.includes(key) && !isNaN(parseInt(stats[key]))) {
            aggregatedData[id][key] =
              (parseInt(aggregatedData[id][key]) || 0) + parseInt(stats[key]);
          }
        });
      } else {
        aggregatedData[id] = {
          ...stats,
          season_name: "All Seasons",
          ...teams.find((item) => item.id.toString() === teamId),
        };
      }
    });
    let tempStats = Object.values(aggregatedData);

    setTopPlayers(getSortedData(tempStats));
  };

  const getStats = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.statsList });
    setLoading(false);
    for (let key in data) {
      if (!data[key].length) return;
    }
    if (data)
      statsData.fn({ player: data.playerstatslist, team: data.teamstatslist });
  };

  useEffect(() => {
    if (!statsData.var?.player) getStats();
    else if (statsData.var.player.length && teams) getTopPlayers();
  }, [statsData.var, teams]); // eslint-disable-line

  useEffect(() => {
    if (pathname.split("/").length - 1 === 1) {
      handleClosePopup();
      document.title = "Patna Pirates | Members Lounge";
    }
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    if (hfAction.var) handleNavigation(hfAction.var);
  }, [hfAction.var]); // eslint-disable-line

  useEffect(() => {
    showHF(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    let interval = null;
    if (isVideoEnded) {
      interval = setInterval(() => {
        if (mainRef.current) {
          if (mainRef.current.scrollWidth > width)
            mainRef.current.scrollLeft =
              (mainRef.current.scrollWidth - width) / 2;
          clearInterval(interval);
        }
      }, 50);
    }
    return () => clearInterval(interval);
  }, [isVideoEnded, width]);

  return (
    <div className="wrapper" ref={mainRef}>
      {isVideoEnded ? (
        <>
          {pathname.split("/").length - 1 > 1 ? (
            <Popup onClose={handleClosePopup} {...popupProps.var}>
              <Outlet context={{ close: handleClosePopup }} />
            </Popup>
          ) : (
            <div className="imageOuter membersLoungePage">
              <img src={membersLoungeBg} alt="" />
              <div className="hotspots">
                {width > 990 && height > 677 && (
                  <div className="topUserListing">
                    <div className="top-leaderborad stats-tbl-top row">
                      <div className="col-md-1 col-sm-1 col-2">
                        <div className="top-leaderName">Rank</div>
                      </div>
                      <div className="col-md-4 col-sm-5 col-2">
                        <div className="top-leaderName">Name</div>
                      </div>
                      <div className="col-md-3 col-sm-3 col-3">
                        <div className="top-leaderName">Played</div>
                      </div>
                      <div className="col-md-3 col-sm-3 col-3">
                        <div className="top-leaderName statspoint">Points</div>
                      </div>
                    </div>
                    <Scrollbar loading={loading} minHeight={100}>
                      {topPlayers?.map(
                        (player, key) =>
                          key < 3 && (
                            <div
                              className="leaderborad-listing stats-tbl2 row"
                              key={key}
                              style={height < 900 ? { padding: 5 } : {}}
                            >
                              <div className="col-md-1 col-1 col-xs-2">
                                <div className="leaderborad-sr">
                                  <span className="rankCount">{key + 1}</span>
                                </div>
                              </div>
                              <div className="col-md-5 col-5 col-xs-4">
                                <div className="userListing">
                                  <div className="leaderpic team-pic2">
                                    <img src={player.logo} alt="" />
                                  </div>
                                  <div className="leadername team-name2">
                                    {player.player_name.includes(" ")
                                      ? player.player_name.split(" ")[0]
                                      : player.player_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-3 col-xs-3">
                                <div className="leadername2">
                                  {player.total_match_played}
                                </div>
                              </div>
                              <div className="col-md-3 col-3 col-xs-3">
                                <div className="leadername2">
                                  {player.total_points}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          )
                      )}
                    </Scrollbar>
                  </div>
                )}
                {loungeHotspots.map((item, index) => (
                  <div
                    className={`hotspot-item hotspot-item-${index + 1}`}
                    key={index}
                    name={item.name}
                    onClick={() => handleNavigation(item.name)}
                  >
                    <Link name={item.name}>
                      <span className="pulsating-circle" name={item.name} />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="videoOuter">
          <video controls={false} autoPlay={true} onEnded={onVideoEnded} muted>
            <source src={towardsLounge} type="video/mp4" />
          </video>
          <button type="button" className="btn skip" onClick={onVideoEnded}>
            SKIP
          </button>
        </div>
      )}
    </div>
  );
};

export default MembersLounge;
